<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    /** 测试 start */
    // this.$utils.setLs("tokenInfo", {
    //   loginusertoken: "",
    //   nickname: "昵称",
    //   avatar: "",
    // });

    // setTimeout(() => {
    //   this.$utils.setLs("tokenInfo", {
    //     loginusertoken: "AmWqLV2KEl9d1v8X",
    //     nickname: "昵称",
    //     avatar: "",
    //   });
    // }, 2000);
    /** 测试 end */

    setTimeout(() => {
      this.callNative();
    }, 200);
    
    //login
    this.$bridge.registerhandler("loginState", () => {
      this.callNative();
    });
  },

  methods: {
    async callNative() {
      try {
        this.$bridge.callhandler("userInfo", { type: "optionType" }, (data) => {
          if (data) {
            if (data.loginusertoken) {
              this.$utils.setLs("tokenInfo", data);
            } else {
              this.$bridge.callhandler("showLogin", () => {});
            }
          }
        });
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
