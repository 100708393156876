import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "vip" */ "@/views/main/main.vue"),
    children: [
      {
        path: "/",
        name: "vip_index",
        meta: { title: "行云会员", keepAlive: true },
        component: () =>
          import(/* webpackChunkName: "vip_index" */ "@/views/vip/index.vue"),
      },
      {
        path: "/vip_pay",
        name: "vip_pay",
        meta: { title: "结算台", keepAlive: false },
        component: () =>
          import(/* webpackChunkName: "vip_pay" */ "@/views/vip/pay.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
