const getTimeStamp = () => Math.round(new Date().getTime() / 1000);

const m_toString = Object.prototype.toString;
const m_isArr = Array.isArray;

export const isFn = (fn) => {
  if (!fn) return;
  return m_toString.call(fn) === "[object Function]";
};

export const isObj = (obj) => {
  if (!obj) return;
  return m_toString.call(obj) === "[object Object]";
};

export const isDef = (v) => v !== undefined && v !== null && v !== "";

export const isFalse = (v) => v === false;

export const isTrue = (v) => v === true;

export const isNum = (num) =>
  m_toString.call(num) === "[object Number]" && !Number.isNaN(num);

// export const toNumber = (v) => {
//   const m_v = parseFloat(v);
//   return Number.isNaN(m_v) ? v : _v;
// };

export const toArray = (data) => (m_isArr(data) && data) || [];

export const toObj = (data) => (isObj(data) && data) || {};

// storage操作
export const storage = {
  getSs(key) {
    const data = window.sessionStorage.getItem(key);
    if (isDef(data)) {
      return JSON.parse(data);
    }
  },
  setSs(key, data, time) {
    // window.sessionStorage.setItem(key, JSON.stringify(data), time);

    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val, time) {
        window.sessionStorage.setItem(k, val, time);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          k,
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );

        // 派发对象
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, JSON.stringify(data), time);
  },
  rmSs(key) {
    window.sessionStorage.removeItem(key);
  },
  getLs(key) {
    const data = window.localStorage.getItem(key);
    if (isDef(data)) {
      return JSON.parse(data);
    }
  },
  setLs(key, data, time) {
    // window.localStorage.setItem(key, JSON.stringify(data), time);

    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val, time) {
        window.localStorage.setItem(k, val, time);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent(
          k,
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );

        // 派发对象
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, JSON.stringify(data), time);
  },
  rmLs(key) {
    window.localStorage.removeItem(key);
  },
  setCk(name, value, day = 0) {
    const exdate = new Date();
    exdate.setDate(exdate.getDate() + day);
    const expiredays = day ? `;expires=${exdate.toGMTString()};path=/` : "";
    document.cookie = `${name}=${escape(value)}${expiredays}`;
  },
  getCk(name) {
    if (document.cookie.length > 0) {
      let start = document.cookie.indexOf(`${name}=`);
      if (start > -1) {
        start = start + name.length + 1;
        let end = document.cookie.indexOf(";", start);
        if (end === -1) end = document.cookie.length;
        return unescape(document.cookie.substring(start, end));
      }
    }
    return "";
  },
  rmCk(name) {
    const exp = new Date();
    exp.setTime(exp.getTime() - 1);
    const cval = storage.getCk(name);
    if (isDef(cval)) {
      document.cookie = `${name}=${cval};expires=${exp.toGMTString()}`;
    }
  },
};

/**
 * 判断json对象是否为空
 * @param {*} obj
 * @returns true 空    false 不为空
 */
export const isEmptyObject = (obj) => {
  for (const name in obj) {
    return false;
  }
  return true;
};

export default {
  isFn,
  isObj,
  isFalse,
  isTrue,
  isNum,
  isDef,
  // toNumber,
  toArray,
  toObj,
  getTimeStamp,
  ...storage,
  isEmptyObject,
};
