/**
 * @description 配置网络请求
 */
import axios from "axios";
import { Message } from "element-ui";
// import Vue from 'vue';
import utils from "@/utils";

const { Base64 } = require("js-base64");
const serverHost = process.env.VUE_APP_URL;
const appid = "test";

// 获取请求时发送所需的Authentication
const headerAuthentication = () => {
  let BASE64STR = "";
  let authentication = "";
  const tokenInfo = utils.getLs("tokenInfo") || {};
  if (!utils.isEmptyObject(tokenInfo)) {
    BASE64STR = Base64.encode(`${appid}:${tokenInfo.access_token}`);
    authentication = `TOKEN ${BASE64STR}`;
  }
  return authentication;
};
// 获取请求时发送所需的Loginusertoken
// const headerLoginusertoken = () => {
//   const userInfo = utils.getLs("userInfo") || {};
//   let lguser_token = "";
//   if (!utils.isEmptyObject(userInfo)) {
//     lguser_token = userInfo.lguser_token;
//   }

//   return lguser_token;
// };

// loading框设置局部刷新，且所有请求完成后关闭loading框
// let loading;
// function startLoading() {
//   loading = Vue.prototype.$loading({
//     lock: true,
//     text: 'Loading...',
//     target: document.querySelector('.loading-area') // 设置加载动画区域
//   });
// }
// function endLoading() {
//   loading.close();
// }
// 声明一个对象用于存储请求个数
// const needLoadingRequestCount = 0;
// function showFullScreenLoading() {
//   if (needLoadingRequestCount === 0) {
//     startLoading();
//   }
//   needLoadingRequestCount++;
// }
// function tryHideFullScreenLoading() {
//   if (needLoadingRequestCount <= 0) return;
//   needLoadingRequestCount--;
//   if (needLoadingRequestCount === 0) {
//     endLoading();
//   }
// }
// 请求拦截
axios.interceptors.request.use(
  (config) => {
    // if (config.params && !config.params.showLoading && !(config.url.indexOf('/makePay') >= 0)) {
    //   showFullScreenLoading();
    // }

    const tokenInfo = utils.getLs("tokenInfo") || {};
    if (!utils.isEmptyObject(tokenInfo)) {
      config.headers.authentication = headerAuthentication();
      config.headers.loginusertoken = tokenInfo.loginusertoken;

      config.headers.clientversion = "a-1.0";
    }
    return config;
  },
  (err) => {
    console.log(err);
    // tryHideFullScreenLoading();

    // Message.error({ message: err });
    return Promise.resolve(err);
  }
);
// 响应拦截
axios.interceptors.response.use(
  (res) => {
    // tryHideFullScreenLoading();

    if (res.data.code !== 200) {
      if (`${res.data.code}`.indexOf("900") >= 0) {
        Message.error({ message: res.data.message });
      } else {
        Message.error({ message: res.data.data.info });
      }

      return Promise.reject(res.data.data);
    }

    return Promise.resolve(res.data.data);
  },
  (error) => {
    // tryHideFullScreenLoading();
    console.log(error);
    if (error.message === "Network Error") {
      // utils.rmLs('tokenInfo');
    }
    // Message.error({ message: error.message });
  }
);
axios.defaults.timeout = 300000; // 请求超时5fen

const getRequest = (url, data = "") => {
  console.log(serverHost + url);
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      params: data,
      url: serverHost + url,
    })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// RequestBody    post
const postJsonRequest = (url, params) => {
  console.log(serverHost + url);
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: serverHost + url,
      data: params,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// RequestBody    put
const putJsonRequest = (url, params) => {
  console.log(serverHost + url);
  return new Promise((resolve, reject) => {
    axios({
      method: "put",
      url: serverHost + url,
      data: params,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// 上传
const uploadRequest = (url, params) => {
  console.log(serverHost + url);
  console.log(params);
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: serverHost + url,
      data: params,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        console.log(res);
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export default {
  getRequest,
  postJsonRequest,
  putJsonRequest,
  uploadRequest,
};
